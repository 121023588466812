<template>
  <div
    class="home"
    v-if="page.length"
  >
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        alt=""
        class="width-100"
      >
      <div
        class="absolute"
        :style="isMobile ? 'top: 25%;left:0;width:100%; text-align:center;' : 'top: 35%;left:0;width:100%; text-align:center; font-size:35px;'"
      >
        <div
          class="b"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="zoom-out-down"
        >
          <div v-if="isMobile">
            <div style="font-size: 26px; margin-bottom: 5px;">
              {{ page[0].t1 }}
            </div>
            <div style="font-size: 22px;">
              {{ page[0].t2 }}
            </div>
          </div>
          <div v-else>
            {{ page[0].t1 }} ， {{ page[0].t2 }}
          </div>
        </div>
        <div
          class="c-666"
          :class="isMobile ? 'f16 c-000 ' : 'c-555 f20 mt30'"
          :style="isMobile ? 'width:80%; margin:30px auto 0 auto;' : ''"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="zoom-out-down"
          data-aos-delay="200"
        >
          {{ page[0].t3 }}
          {{ page[0].t4 }}
        </div>
      </div>
    </div>

    <div class="relative tc">
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-out-down"
        data-aos-delay="300"
      >
        {{ page[1].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div
        class="relative z1"
        style="font-weight: lighter;"
        :style="isMobile ? 'font-size:18px;padding:10px 0 20px' : 'font-size:30px;padding:20px 0 30px'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="zoom-out-down"
      >
        {{ page[1].t2 }}
      </div>
      <div class="relative"
           :style="isMobile ? '' : 'padding: 0 10%; background:#FCFCFC;' ">
        <div
          v-if="!isMobile"
          class="absolute t0 b0 width-100 l0"
        >
          <div class="flex width-100 vw-pt20">
            <div class="width-50">
              <div
                v-for="(item,i) in page[1].t3.slice(0,4)"
                :key="item"
                :style="{
                  paddingTop:[`12px`][i],
                  marginBottom: [`2px`, `-6px`, `5px`, `0`][i],
                  paddingRight: [`${60/60}vw`, `${100/9.5}vw`, `${100/11}vw`, `${60/60}vw`][i]
                }"
                v-vue-aos.once="{animationClass:'fadeInUp animated'}"
                data-aos="fade-left"
              >
                <div
                  class="dib plr10 vw-mb20"
                  :style="{ fontSize: 8/6 + 'vw' }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="width-50">
              <div
                v-for="(item,i) in page[1].t3.slice(4)"
                :key="item"
                :style="{
                  paddingTop:[`14px`][i],
                  marginBottom: [`-2px`, `-2px`, `5px`, `0`][i],
                  paddingLeft: [`${60/7.5}vw`, `${100/9.5}vw`, `${100/16}vw`, `${0}vw`][i]
                }"
                v-vue-aos.once="{animationClass:'fadeInUp animated'}"
                data-aos="fade-right"
              >
                <div
                  class="dib pr20 vw-mb20"
                  :style="{ fontSize: 8/6 + 'vw', textAlign: 'left' }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          :src="isMobile ? page[1].img_m : page[1].img"
          alt=""
          class="width-100"
          :style="isMobile ? 'margin-top:-100px' : ''"
        >
      </div>
      <div
        v-if="isMobile"
        class="absolute width-100 vw-b50 pt30"
        :style="{ height: 280/3.75 + 'vw', color: '#587285' }"
      >
        <div class="flex width-100">
          <div class="width-50">
            <div
              v-for="(item,i) in page[1].t3.slice(0,4)"
              :key="item"
              :style="{
                paddingRight: [0, '60px', '80px', '50px'][i]
              }"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
            >
              <div
                class="dib plr10 bg-fff mb30"
                style="border:1px #E6EFF7 solid;border-radius:10px;"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="width-50">
            <div
              v-for="(item,i) in page[1].t3.slice(4)"
              :key="item"
              :style="{
                paddingLeft: [0, '60px', '80px', '50px'][i]
              }"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
            >
              <div
                class="dib plr10 bg-fff mb30"
                style="border:1px #E6EFF7 solid;border-radius:10px;"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative tc">
      <div :style="isMobile && 'background:#E3F3FE;padding: 30px 0 100px 0;'">
        <div
          :style="isMobile ? 'font-size:24px;' : 'font-size:40px;padding: 80px 0 10px 0;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[2].t1 }}
        </div>
        <div
          class="dib"
          style="width: 50px;height: 3px;background: #ed7989;"
        />
      </div>
      <div
        :class="isMobile ? '' : 'flex-center mt50 pb50'"
        :style="isMobile && 'margin-top:-70px;'"
      >
        <div
          v-for="(item, i) in page[2].t2"
          :key="i"
          style="background: #F1F9FE;"
          :style="isMobile ? 'height:115px; justify-content: left;' : 'width:210px;height:270px'"
          class="br5 mlr15"
          :class="isMobile ? 'flex-center plr15 mb15' : 'pt15'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-down"
          data-aos-duration="1000"
        >
          <img
            :style="{ width: isMobile ? '60px' : '90px' }"
            :src="[site.icon.i1,site.icon.i2,site.icon.i3,site.icon.i4,site.icon.i5,site.icon.i6][i]"
            alt=""
          >
          <div :class="isMobile && ''">
            <div
              :style="isMobile ? 'text-align: left; color:#ed7989;' : 'text-align: center; margin-bottom:10px;'"

              class="pl20 f18"
              :class="{
                mt20: !isMobile
              }"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
            >
              {{ item.t }}
            </div>
            <div
              style="text-align: left;font-weight: lighter"
              class="pl20 f16"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
            >
              {{ item.d }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="relative tc pb50"
      :class="!isMobile && 'bg-f7'"
    >
      <div
        :style="isMobile && 'background:#E3F3FE;margin:30px 20px;padding:30px 0'"
      >
        <div
          :style="isMobile ? 'font-size:24px;' : 'font-size:40px;padding: 80px 0 10px 0;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[3].t1 }}
        </div>
        <div
          class="dib"
          style="width: 50px;height: 3px;background: #ed7989;"
        />
      </div>
      <div
        :class="isMobile ? '' : 'flex-center mt50 pb50'"
      >
        <div
          v-for="(item, i) in page[3].t2"
          :key="i"
          class="bg-fff br4 oh"
          :class="isMobile ? 'mlr20 mb30' : 'mlr10'"
          :style="isMobile ? '' : 'width:420px;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-down"
          data-aos-duration="1000"
        >
          <div
            v-if="isMobile"
            class="tl"
          >
            <div
              class="f18"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
            >
              {{ item.t }}
            </div>
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
              class="f14 c-999 bd bb bd-aaa pb20 mb20 mt15"
            >
              {{ item.d }}
            </div>
          </div>
          <img
            :src="item.img"
            alt=""
            style="width:100%;height:290px"
          >
          <div
            v-if="!isMobile"
            class="p20 tl"
          >
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
              class="dib f20 bd bb bd-000 pb15 mb15"
            >
              {{ item.t }}
            </div>
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-out-down"
              class="c-666 f14"
            >
              {{ item.d }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',

  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/quanrenyuan.json')
    this.page = data
  },

  methods: {
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    }
  }
}
</script>
